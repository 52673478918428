import React, { useState } from 'react';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SearchItem from "../components/searchItem"
import { useFlexSearch } from 'react-use-flexsearch';

import "../styles/search.css"

export const pageQuery = graphql`
  query {
    allWpPost {
      nodes {
        uri
        title
        slug
        resources {
          featuredImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 363, height: 231)
              }
            }
          }
          excerpt
          callToActionLabel
        }
      }
    }
    localSearchPages {
      index
      store
    }
  }
`


const Search = ({location, data: {
    localSearchPages: { index, store },
    allWpPost: { nodes }
  }}) => {

  function unflattenResults (results) {
    results.map(post => {
        const { uri, slug, title, featuredImage, excerpt } = post;
        return { slug, title, uri, featuredImage, excerpt };
    });
  }

  const { search } = location;
  const query = new URLSearchParams(search).get('s');
  const [searchQuery, setSearchQuery] = useState(query || '');

  //const posts = nodes;
  const results = useFlexSearch(searchQuery, index, store);
  const posts = results; // unflattenResults(results);

    return (
      <Layout>
        <Seo title="Search Results" seo="" />
        <section className="search-results-wrapper block-vw--btm bg-black">
          <div className="wrapper wrapper-md">
          <header>
            <h3 className="font-28 font-w-300 color-white align-center">Search results for: ‘{searchQuery}’</h3>
          </header>
           <div className="d-sm-flex flex-wrap row-40">
             {posts.map(post => (
                 <SearchItem item={post} />
             ))}
           </div>
          </div>
        </section>
      </Layout>
    )
  }

  export default Search
