import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

function linkUrl(item) {
  if(item.type === 'whitepaper')  {
    return <a href={item.resources.whitepaper} target="_blank" className="link-arrow">{item.resources.callToActionLabel}</a>
  } else {
    return <a href={item.uri} className="link-arrow">{item.resources.callToActionLabel}</a>
  }
}

const SearchItem = ({ item }) => (
  <div className="col-sm-6 col-md-4 gap-40 margin-top--64">
  {item.featuredImage &&
    <div><GatsbyImage image={item.featuredImage.localFile.childImageSharp.gatsbyImageData} alt={item.title} /></div>
  }
    <h3 className="headline-5 font-w-300 margin-top--24">{item.title}</h3>
    <div className="margin-top--8 opacity-50 font-20">{item.excerpt}</div>
    <div className="margin-top--24"><a href={item.uri} className="link-arrow color-white">Read article</a></div>
  </div>
)

export default SearchItem
